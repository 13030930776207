@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

.ad {
  margin: 20px;
  padding: 20px;
  border: 1px solid $colour-oyster-grey;
  align-self: flex-start;

  img {
    display: block;
    margin: -20px -20px 0;
    width: calc(100% + 40px);
  }
}

.promo {
  color: $colour-offer-red;
}
